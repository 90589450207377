import axiosInstance from "@helper/interceptor";

export const getAllSensors = async () => {
  let response = await axiosInstance.get("sensors");
  return response.data;
};
export const getSensorsProject = async (id) => {
  let response = await axiosInstance.get(`project/${id}/sensors`);
  return response.data;
};
export const getSensor = async (id) => {
  let response = await axiosInstance.get(`sensors/${id}`);
  return response.data;
};

export const getLastSend = async (id) => {
  let response = await axiosInstance.get(`last-send/${id}`);
  return response.data;
};

export const getInfluxData = async (start, stop, interval, hardwareSerial) => {
  let response = await axiosInstance.get(
    `api/influx-data/?start=${start}&stop=${stop}&interval=${interval}&hardwareSerial=${hardwareSerial}`
  );
  return response.data;
};

export const createSensor = async (formData) => {
  const response = await axiosInstance.post("sensors/", formData);
  return response;
};

export const updateSensor = async ({ updateFormData, detail }) => {
  try {
    const response = await axiosInstance.put(`sensors/${detail.id}/`, updateFormData);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
export const deleteSensor = async (id) => {
  try {
    const response = await axiosInstance.delete(`sensors/${id}`);
    return response;
  } catch (error) {
    throw new Error({
      message: "Can't delete this sensor.There are some tree linked with this sensor",
    });
  }
};

export const sensorPlot = async (sensor) => {
  let type = "resistance";
  if (sensor.soil_moisture_sensor) {
    type = "water_soil";
  } else if (sensor.dendrometer_sensor) {
    type = "dendrometer";
  } else if (sensor.soil_water_tension_sensor) {
    type = "soil_water_tension";
  }
  const response = await axiosInstance.get(`ts_data/${sensor.id}-${type}`);
  return response.data;
};

export const installationPlot = async (installation) => {
  let type = "resistance";
  if (installation.sensor.soil_moisture_sensor) {
    type = "water_soil";
  } else if (installation.sensor.dendrometer_sensor) {
    type = "dendrometer";
  } else if (installation.sensor.soil_water_tension_sensor) {
    type = "soil_water_tension";
  }
  let response = await axiosInstance.get(
    `data/${installation.tree.id}+${installation.sensor.id}-${type}`
  );
  response.data.x = response.data.x[installation.tree.id];
  response.data.y = response.data.y[installation.tree.id];
  return response.data;
};
