import { Field } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  zipCode: Yup.string().matches(/^\d{5}$/, "ZIP code must be exactly 5 digits"),
});

export default function UserAddress({ formField }) {
  const { zipCode, streetName, houseNumber, city, country } = formField;

  const { t } = useTranslation();

  const STREET_NAME_LIMIT = 255;
  const NAME_LIMIT = 50;

  return (
    <>
      <div className="d-flex align-items-center gap-2">
        <Form.Group className="flex-fill ">
          <Form.Label>{t(streetName.label)}</Form.Label>
          <Field
            type="text"
            name={streetName.name}
            className="form-control mb-3"
            maxLength={STREET_NAME_LIMIT}
            placeholder={t("maximum_of") + ` ${STREET_NAME_LIMIT} ` + t("characters")}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(zipCode.label)}</Form.Label>

          <Field
            type="number"
            name={zipCode.name}
            className="form-control mb-3"
            validationSchema={validationSchema}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(houseNumber.label)}</Form.Label>
          <Field type="number" name={houseNumber.name} className="form-control mb-3" min="0" />
        </Form.Group>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Form.Group className="flex-fill">
          <Form.Label htmlFor="">{t(city.label)}</Form.Label>
          <Field
            type="text"
            name={city.name}
            className="form-control "
            maxLength={NAME_LIMIT}
            placeholder={t("maximum_of") + ` ${NAME_LIMIT} ` + t("characters")}
          />
        </Form.Group>
        <Form.Group className="flex-fill">
          <Form.Label>{t(country.label)}</Form.Label>
          <Field
            type="text"
            name={country.name}
            className="form-control "
            maxLength={NAME_LIMIT}
            placeholder={t("maximum_of") + ` ${NAME_LIMIT} ` + t("characters")}
          />
        </Form.Group>
      </div>
    </>
  );
}
