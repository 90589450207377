import Skeleton from "./Skeleton";
import useFilter from "@hooks/useFilter";
import { useQueries } from "react-query";
import { Collapse } from "react-bootstrap";
import { styles } from "@components/styles";
import { useTranslation } from "react-i18next";
import { constants } from "../context/constants";
import { TriangleUpIcon } from "@components/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { getTree } from "@modules/Trees/services/treeCrud";
import React, { useState, useEffect, useMemo } from "react";
import { DataScienceContext } from "../context/DataScienceContext";
import { shadeColor } from "@helper/colors";

function countSensorsOfTreeBefore(arr, idx) {
  let count = 0;
  for (let i = 0; i < idx; i++) {
    if (arr[i].split(" ")[0] === arr[idx].split(" ")[0]) {
      count++;
    }
  }

  return count;
}

function SensorList() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [filterTerm, setFilterTerm] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const query = new URLSearchParams(useLocation().search);
  const queryString = query.toString();
  let tree_sensor_pairs_query = query.get("treeSensors");
  if (tree_sensor_pairs_query) {
    const tree_sensor_pairs_query_list = [...new Set(tree_sensor_pairs_query.split(","))];
    tree_sensor_pairs_query = tree_sensor_pairs_query_list.join(",");
  }
  const {
    pid,
    filterState,
    filterDispatch,
    lineColor,
    setLineColor,
    treeLineColor,
    setActiveSensors,
  } = React.useContext(DataScienceContext);
  const { sensorId, tree_sensor_pairs, tree_sensor_pairs_tree, treesId, areasId } = filterState;
  const [sensors, setSensors] = useState([]);

  var serials = tree_sensor_pairs_query;
  var tsps = tree_sensor_pairs_query;
  if (tree_sensor_pairs_query) {
    serials = tree_sensor_pairs_query
      .split(",")
      .map((tsp) => {
        return tsp.split(" ")[1];
      })
      .join(",");
    tsps = tree_sensor_pairs_query.split(",");
  }

  const filteredSensors = useMemo(
    () => useFilter({ filterTerm, filterItem: tree_sensor_pairs_tree }),
    [filterTerm, tree_sensor_pairs, tree_sensor_pairs_tree]
  );

  useEffect(() => {
    // Initialize all checkboxes to unchecked
    const initialStates = {};
    filteredSensors.forEach((sensor) => {
      // initialStates[sensor.sensor] = false;
      initialStates[sensor.sensor] = true;
    });
    setCheckboxStates(initialStates);
  }, [filteredSensors]);

  useEffect(() => {
    if (serials) {
      const serial = serials.split(",").map(String);
      const tree_sensors = tree_sensor_pairs_query.split(",").map((tsp) => {
        return { tree: tsp.split(" ")[0], sensor: tsp.split(" ")[1] };
      });
      filterDispatch({
        type: constants.SET_FILTERED_HARDWARE_SERIAL,
        payload: [serial, tree_sensors],
      });
    }
  }, [serials]);

  useEffect(() => {
    // Build new URL only with tree_sensor_pairs that correspond to currently selected trees
    const filteredTreeSensorPairs = tree_sensor_pairs.filter((pair) =>
      treesId.includes(parseInt(pair.tree))
    );

    if (treesId.length > 0 && areasId) {
      const tsp = filteredTreeSensorPairs.map((pair) => `${pair.tree}+${pair.sensor}`);
      const newUrl = `/project/${pid}/datascience?areas=${areasId.join(",")}&trees=${treesId.join(
        ","
      )}${tsp.length ? `&treeSensors=${tsp.join(",")}` : ""}`;
      navigate(newUrl);
    }
  }, [tree_sensor_pairs, treesId, areasId, pid, navigate]);

  //1. fetch the sensors related to particular tree
  const results = useQueries(
    treesId.map((treeId) => {
      return {
        queryKey: ["tree-sensor", treeId],
        queryFn: () => getTree(treeId),
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          const data_sensors = data.installations.map((installation) => {
            return installation.sensor;
          });
          const tree_sensors = data_sensors.map((sensor) => {
            return {
              tree: data.id,
              sensor: sensor.id,
              hardware_serial: sensor.hardware_serial,
              sensor_name: sensor.name,
              tree_name: data.name,
            };
          });
          data_sensors.length !== 0 &&
            filterDispatch({
              type: constants.SET_SENSORS,
              payload: [data_sensors, tree_sensors],
            });
          setSensors(sensors.concat(data_sensors));
        },
      };
    })
  );
  const isLoading = results.some((a) => a.isLoading);
  const isFetching = results.some((a) => a.isFetching);

  //refetch the sensor list based on treelist checked and unchecked
  useEffect(() => {
    filterDispatch({ type: constants.SET_SENSORS, payload: [] });
    results.forEach((result) => result.refetch());
  }, [treesId]);

  //4. This function used to set the value for tree search on key change to input field.
  function updateFilterHandler(event) {
    setFilterTerm(event.target.value);
  }

  //set and filter hardware serial on check and uncheck
  const handleCheck = (props) => {
    const { e, sensor } = props;
    const sensor_id = sensor.sensor;
    const tree_sensors = sensor;
    const isChecked = e.target.checked;

    // Update local checkbox state
    setCheckboxStates((prev) => {
      const updatedStates = { ...prev, [sensor_id]: isChecked };

      // After updating the individual checkbox state, check if all are still checked
      const allChecked = Object.values(updatedStates).every((state) => state);
      setSelectAllChecked(allChecked);

      return updatedStates;
    });

    if (isChecked) {
      filterDispatch({
        type: constants.SET_HARDWARE_SERIAL,
        payload: [[sensor_id], [tree_sensors]],
      });
    } else {
      const filteredSerial = sensorId.filter((item) => +item !== sensor_id);
      const filtered_tree_sensors_pairs = tree_sensor_pairs.filter(
        (item) => +item.tree !== tree_sensors.tree || +item.sensor !== tree_sensors.sensor
      );
      filterDispatch({
        type: constants.SET_FILTERED_HARDWARE_SERIAL,
        payload: [filteredSerial, filtered_tree_sensors_pairs],
      });
    }
  };

  //set color to line on the base of hardware serials selected
  function getColor() {
    let sources = tsps;
    let colors_final = {};
    let colors_tree = {};
    if (
      sources &&
      Object.keys(treeLineColor).length != 0 &&
      !Object.values(treeLineColor).some((item) => item === undefined)
    ) {
      for (let i = 0; i < sources.length; i++) {
        const tree = sources[i].split(" ")[0];
        const amount = countSensorsOfTreeBefore(tsps, i);
        const color = treeLineColor[tree];
        const factor = parseInt((amount + 1) / 2);
        const sig = (-1) ** (amount % 2);
        const colorSensor = shadeColor(color, sig * factor * 30);
        colors_final[sources[i]] = colorSensor;
        colors_tree[tree] = color;
      }
      setLineColor(colors_final);
    }
  }

  useEffect(() => {
    if (Object.keys(lineColor).length == 0) {
      getColor();
    }
    if (tsps) {
      getColor();
    }
    const allChecked = Object.values(checkboxStates).every((state) => state);
    setSelectAllChecked(allChecked);
  }, [queryString]);

  const handleSelectAll = (props) => {
    const { e, filteredSensors } = props;
    const isChecked = e.target.checked;

    // Update all checkboxes to the state of the "Select All" checkbox
    setCheckboxStates(() => {
      const newStates = {};
      filteredSensors.forEach((sensor) => {
        newStates[sensor.sensor] = isChecked;
      });
      return newStates;
    });

    // Update the "Select All" checkbox state
    setSelectAllChecked(isChecked);

    if (isChecked) {
      const ids = filteredSensors.map((sensor) => sensor.sensor);
      const tree_sensors = filteredSensors;
      filterDispatch({ type: constants.SET_HARDWARE_SERIAL, payload: [ids, tree_sensors] });
    } else {
      setLineColor({});
      filterDispatch({ type: constants.SET_FILTERED_HARDWARE_SERIAL, payload: [[], []] });
    }
  };

  useEffect(() => {
    const activeSensors = tree_sensor_pairs
      .map((t) => {
        const matchedSensor = sensors.find((s) => s.id === +t.sensor);
        if (matchedSensor) {
          return matchedSensor;
        }
        return null; // or return s if you want to keep the original sensor when there's no match
      })
      .filter(Boolean);
    setActiveSensors(activeSensors);
  }, [tree_sensor_pairs, sensors]);

  return (
    <div className="me-3">
      <div className="d-flex pe-2 justify-content-between">
        <div
          className={styles.dsFilterTitle}
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        >
          <TriangleUpIcon open={open} />
          <span className=" ds-filter-title">{t("sensors")} </span>
        </div>
        <div>
          <input
            type="checkbox"
            checked={selectAllChecked}
            onChange={(e) => handleSelectAll({ e, filteredSensors })}
          />
        </div>
      </div>
      <Collapse in={open}>
        <div>
          {tree_sensor_pairs_tree.length !== 0 && (
            <div className="py-2 pe-2">
              <input
                type="text"
                className="form-control filter-search"
                placeholder={t("search_sensor")}
                onChange={updateFilterHandler}
              />
            </div>
          )}
          {isLoading || isFetching ? (
            <>
              {[1, 2, 3, 4, 5].map((a, i) => (
                <Skeleton key={i} />
              ))}
            </>
          ) : (
            <ul className="p-0 pe-2 filter__list" id="example-collapse-text">
              {filteredSensors?.map((sensor, i) => {
                return (
                  <li
                    key={i}
                    className={`filter__items d - flex justify - content - between ps - 1 `}
                  >
                    {sensor.tree_name + " " + sensor.sensor_name}
                    {lineColor && (
                      <input
                        type="checkbox"
                        onChange={(e) => handleCheck({ e, sensor })}
                        style={{
                          background:
                            tsps && lineColor && tsps.includes(`${sensor.tree} ${sensor.sensor}`)
                              ? lineColor[sensor.tree + " " + sensor.sensor]
                              : "#fff",

                          border:
                            tsps && lineColor && tsps.includes(`${sensor.tree} ${sensor.sensor}`)
                              ? lineColor[sensor.tree + " " + sensor.sensor]
                              : "1px solid #ccc",
                          outline:
                            tsps && lineColor && tsps.includes(`${sensor.tree} ${sensor.sensor}`)
                              ? `1px solid ${lineColor[sensor.tree + " " + sensor.sensor]}`
                              : "1px solid #388090",
                        }}
                        //    checked={checkboxStates[sensor.sensor] || false}
                        checked={
                          tsps && tsps.includes(`${sensor.tree} ${sensor.sensor}`) ? "checked" : ""
                        }
                      />
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default SensorList;
