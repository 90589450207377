import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Avatar from "react-avatar";
import { DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import ReactTable from "@components/ReactTable";
import StatusButton from "./Button/StatusButton";
import { deleteTree } from "../services/treeCrud";
import useChoices from "@hooks/useChoices";
import { AngleDownIcon, EditIcon, TrashIcon, ImageAddIconSmall } from "@components/icons";
import { removeProjectTree } from "@modules/Projects/services/projectCrud";

export default function TreeList({
  trees,
  setIsUpdate,
  setOpenForm,
  setFormValue,
  setOpenImageForm,
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  {
    /*const [nextIrrigationOption, setNextIrrigationOption] = React.useState([]);*/
  }
  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const [filterTree, setFilterTree] = React.useState(trees);
  const [selectedStatus, setSelectedStatus] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState([]);
  const tid = query.get("selectedTree");
  const { pid } = useParams();

  {
    /*	React.useEffect(() => {
    const p = new Set(trees.map((tree) => tree.next_irrigation_days));
    setNextIrrigationOption(Array.from(p).sort((a, b) => a - b));
  }, []);	*/
  }

  React.useEffect(() => {
    let b = trees;
    if (selectedStatus.length > 0) {
      b = b.filter((tree) => selectedStatus.includes(tree.health_state));
    }
    if (selectedProject.length > 0) {
      b = b.filter((tree) => selectedProject.includes(tree.area?.project?.id));
    }
    setFilterTree(b);
  }, [selectedStatus, selectedProject, trees]);

  const { mutateAsync: treeDelete } = useMutation(deleteTree, {
    onSuccess: () => {
      queryClient.invalidateQueries("trees");
      queryClient.invalidateQueries(["trees", tid]);
    },
  });
  const { mutateAsync: projectTreeDelete } = useMutation(removeProjectTree, {
    onSuccess: () => {
      queryClient.invalidateQueries(["project", pid]);
    },
  });
  const handleDelete = (tid) => {
    const data = {
      tree_id: tid,
    };
    Swal.fire({
      html:
        '<p className="text-center"> ' +
        "Are you sure you want to delete " +
        // ` <b>${project.name} </b>` +
        "?" +
        "</p>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#388090",
      cancelButtonColor: "#EF3600",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        pid ? projectTreeDelete({ data, pid }) : treeDelete(tid);
      }
    });
  };

  const filterTreesByProject = (project) => {
    const findIdx = selectedProject.indexOf(project);
    if (findIdx > -1) {
      let a = selectedProject.filter((findIdx) => findIdx !== project);
      setSelectedProject(a);
    } else {
      setSelectedProject((prev) => [...prev, project]);
    }
  };
  const filterTreesByStatus = (status) => {
    const findIdx = selectedStatus.indexOf(status);
    if (findIdx > -1) {
      let a = selectedStatus.filter((findIdx) => findIdx !== status);
      setSelectedStatus(a);
    } else {
      setSelectedStatus((prev) => [...prev, status]);
    }
  };
  {
    /*const filterTreesByIrrigationDay = (status) => {
    let b = trees.filter((tree) => tree.next_irrigation_days == status);
    setFilterTree(b);
  };*/
  }
  const uniqueProjects = new Map();
  trees.forEach((tree) => {
    const project = tree.area?.project;
    if (project) {
      uniqueProjects.set(project.id, project);
    }
  });

  const projectList = Array.from(uniqueProjects.values());

  let statusList = [
    { id: 1, label: "health_states.healthy", status: 0 },
    { id: 2, label: "health_states.drying_out", status: 1 },
    { id: 3, label: "health_states.dry", status: 2 },
    { id: 4, label: "health_states.unknown", status: 3 },
    { id: 5, label: "health_states.frost", status: 4 },
    { id: 6, label: "health_states.wet", status: 5 },
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: `${t("Tree name")}`,
        accessor: "name",
        Cell: (props) => {
          return (
            <>
              <div className="d-flex align-items-center gap-3 w-100">
                <Avatar
                  name={props.row.original.name}
                  textMarginRatio={0.25}
                  color={Avatar.getRandomColor("sitebase", ["red", "#8DC641"])}
                  size="28"
                  round="100px"
                />
                <div>
                  <h6 className="mb-0">{props.row.original.name}</h6>
                  <span className="text-muted small">
                    {props.row.original.species ? props.row.original.species.id : ""}
                  </span>
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: `${t("project")}`,
        accessor: "project_name",
        Cell: (props) => {
          return (
            <>
              <div>
                <h6 className="mb-0">
                  {props.row.original.area
                    ? props.row.original.area.project
                      ? props.row.original.area.project.name
                      : ""
                    : ""}
                </h6>
              </div>
            </>
          );
        },
      },
      {
        Header: `${t("area")}`,
        accessor: "area_name",
        Cell: (props) => {
          return (
            <>
              <div>
                <h6 className="mb-0">
                  {props.row.original.area ? props.row.original.area.name : ""}
                </h6>
              </div>
            </>
          );
        },
      },
      {
        Header: `${t("status")}`,
        accessor: "health_state",

        Cell: (props) => {
          // const [healthState] = useChoices("dry_state");
          const healthState = [
            { 0: "Healthy" },
            { 1: "Drying out" },
            { 2: "Dry" },
            { 3: "Unknown" },
            { 4: "Frost" },
            { 5: "Wet" },
          ];

          const health_state = healthState?.filter(
            (item) => item.key == props.row.original.health_state
          );
          let treeHealth = health_state && health_state[0] && health_state[0].value;
          return (
            <>
              {/* {props.row.original.health_state && ( */}
              <StatusButton
                status={props.row.original.health_state}
                width="fit-content"
                padding="1px 10px"
                data-tut="reactour__is2"
              >
                {treeHealth ? `${t(treeHealth.replace(/\s/g, ""))}` : ""}
              </StatusButton>
              {/* )} */}
            </>
          );
        },
      },
      /*{
        Header: `${t("next_irrigation")}`,
        accessor: "tree.position",
        Cell: (props) => {
          let day = "";
          const p = props.row.original.next_irrigation_days;
          if (p == 0) {
            day = t("today");
          } else if (p == 1) {
            day = t("tomorrow");
          } else {
            day = p + t("days_left");
          }

          return (
            <>
              <span className="small text-muted w-50">
                {props.row.original.next_irrigation} |
              </span>
              <span className="ps-1 small fw-bold">{day}</span>
            </>
          );
        },
      },*/
      {
        Header: `${t("actions")}`,
        accessor: "actions",
        style: { width: "100px", textAlign: "center" },
        disableSortBy: true,
        Cell: (props) => {
          return (
            <div className="text-center">
              {!pid && (
                <span title="Edit">
                  <EditIcon
                    onClick={() => {
                      setIsUpdate(true);
                      setOpenForm(true);
                      setFormValue(props.row.original);
                    }}
                  />
                </span>
              )}
              {
                <span title="Upload Image">
                  <ImageAddIconSmall
                    onClick={() => {
                      setOpenImageForm(true);
                      setFormValue(props.row.original);
                    }}
                  />
                </span>
              }
              <span title="Remove">
                <TrashIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    let tid = props.row.original.id;
                    handleDelete(tid);
                  }}
                />
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const onRowClicked = (row) => {
    const { id } = row.original;
    const tid = id;

    if (pid) {
      navigate(`/project/${pid}/list?selectedTree=${tid}`);
    } else {
      navigate(`/trees?selectedTree=${tid}`);
    }
  };

  return (
    <>
      <ReactTable
        columns={columns}
        data={filterTree}
        onRowClicked={onRowClicked}
        activeId={tid}
        size={[10, 20, 30, 40, 50]}
        moreFilter={
          <>
            <DropdownButton
              title={
                <div className="d-flex gap-2">
                  <span>{t("project")}</span>
                  <AngleDownIcon />
                </div>
              }
              variant="btn-transparent"
            >
              {projectList.map((project) => (
                <li key={project.id} className="d-flex align-items-center gap-2 px-2 py-1">
                  <input
                    type="checkbox"
                    onChange={() => filterTreesByProject(project.id)}
                    selected={selectedProject.includes(project.id)}
                  />
                  {project.name}
                </li>
              ))}
            </DropdownButton>
            <DropdownButton
              title={
                <div className="d-flex gap-2">
                  <span>{t("status")}</span>
                  <AngleDownIcon />
                </div>
              }
              variant="btn-transparent"
            >
              {statusList.map((status) => (
                <li key={status.id} className="d-flex align-items-center gap-2 px-2 py-1">
                  <input
                    data-tour="first_project"
                    type="checkbox"
                    onChange={() => filterTreesByStatus(status.status)}
                    selected={selectedStatus.includes(status.status)}
                  />
                  <StatusButton status={status.status} width="12px" height="12px"></StatusButton>{" "}
                  {t(status.label)}
                </li>
              ))}
            </DropdownButton>
            {/*<DropdownButton
							title={
								<div className="d-flex gap-2">
									<span>{t("next_irrigation")}</span>
									<AngleDownIcon />
								</div>
							}
							variant="btn-transparent"
						>
							{nextIrrigationOption.map((status) => (
								<li
									key={status.id}
									className="d-flex align-items-center gap-2 px-2 py-1"
									onClick={() => filterTreesByIrrigationDay(status)}
								>
									{
										{
											0: t("today"),
											1: t("tomorrow"),
										}[status]
									}
									{status >= 2 && status + " " + t("days")}
								</li>
							))}
					</DropdownButton>*/}
          </>
        }
      />
    </>
  );
}
