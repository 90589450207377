import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { ReloadIcon } from "@components/icons";
import { DataScienceContext } from "../context/DataScienceContext";
import DateFilter from "../filters/DateFilter";

function Filter() {
  const [dateType, setDateType] = React.useState(2);
  const {
    setStartDate,
    setEndDate,
    setInitialStartDate,
    setInitialEndDate,
    setLYaxis,
    setRYaxis,
    setLYaxisMean,
    setRYaxisMean,
    setActiveRain,
    setDragItemLeft,
    setDragItemRight,
    setActiveDate,
    setSeries,
    setPositionLeft,
    setPositionRight,
  } = React.useContext(DataScienceContext);
  const { mobile } = useSelector((state) => state.mobile);

  const endDate = moment().add(1, "day").format("YYYY-MM-DD");
  const oneYear = moment().subtract(1, "year").format("YYYY-MM-DD");
  const sixMonths = moment().subtract(6, "months").format("YYYY-MM-DD");
  const threeWeeks = moment().subtract(3, "weeks").format("YYYY-MM-DD");
  const oneWeek = moment().subtract(1, "weeks").format("YYYY-MM-DD");
  const oneDay = moment().subtract(1, "day").format("YYYY-MM-DD");

  React.useEffect(() => {
    switch (dateType) {
      case 0:
        setStartDate(oneYear);
        setEndDate(endDate);
        setInitialStartDate(oneYear);
        setInitialEndDate(endDate);
        setActiveDate(0);
        break;
      case 1:
        setStartDate(sixMonths);
        setEndDate(endDate);
        setInitialStartDate(sixMonths);
        setInitialEndDate(endDate);
        setActiveDate(1);
        break;

      case 2:
        setStartDate(threeWeeks);
        setEndDate(endDate);
        setInitialStartDate(threeWeeks);
        setInitialEndDate(endDate);
        setActiveDate(2);
        break;

      case 3:
        setStartDate(oneWeek);
        setEndDate(endDate);
        setInitialStartDate(oneWeek);
        setInitialEndDate(endDate);
        setActiveDate(3);
        break;
      case 4:
        setStartDate(oneDay);
        setEndDate(endDate);
        setInitialStartDate(oneDay);
        setInitialEndDate(endDate);
        setActiveDate(4);
        break;
      default:
        return;
    }
  }, [dateType]);

  const reset = () => {
    setLYaxis([]);
    setRYaxis([]);
    setLYaxisMean([]);
    setRYaxisMean([]);
    setStartDate(moment().subtract(3, "weeks").format("YYYY-MM-DD"));
    setEndDate(moment().add(1, "day").format("YYYY-MM-DD"));
    setInitialStartDate(moment().subtract(3, "weeks").format("YYYY-MM-DD"));
    setInitialEndDate(moment().add(1, "day").format("YYYY-MM-DD"));
    setActiveRain(false);
    setActiveDate();
    setDragItemLeft();
    setDragItemRight();
    setSeries([]);
    setPositionLeft(false);
    setPositionRight(false);
  };
  const dates = ["1Y", "6M", "3W", "1W", "1D"];
  return (
    <div style={{ position: "absolute", right: "0", top: "0", background: "#fff" }}>
      <div className="reset">
        <ul
          className="py-2"
          style={{
            marginLeft: "auto",
            textAlign: "center",
            width: "38px",
            padding: "0",
            justifyContent: "center",
            display: "flex",
            flexDirection: mobile ? "row" : "column",
            alignItems: "center",
            borderRadius: "3px",
          }}
        >
          <li
            className="text-center btn  border w-100 my-1 py-2 px-1 btn-primary text-white"
            title="Reset"
            type="button"
            onClick={reset}
            disabled
          >
            <ReloadIcon />
          </li>
        </ul>
      </div>
      <div className="ds__date-filter">
        <ul
          className="py-2"
          style={{
            marginLeft: "auto",
            textAlign: "center",
            width: mobile ? "100%" : "38px",
            padding: "0",
            justifyContent: "center",
            display: "flex",
            flexDirection: mobile ? "row" : "column",
            alignItems: "center",
            borderRadius: "3px",
            listStyleType: "none",
          }}
        >
          {dates.map((date, index) => (
            <li
              key={index}
              className={`text-center  border w-100 my-1 py-2 clickable ${
                dateType === index ? "text-primary  bg-secondary" : ""
              }`}
              onClick={() => setDateType(index)}
            >
              {date}
            </li>
          ))}

          <li
            className={`text-center  border w-100 my-1 py-2 clickable ${
              dateType === 5 ? "text-primary fw-bold bg-secondary" : ""
            }`}
          >
            <DateFilter setDateType={setDateType} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Filter;
