import { useState } from "react";
import { Field, Formik } from "formik";
import { useSelector } from "react-redux";
import { styles } from "@components/styles";
import { useTranslation } from "react-i18next";
import Spinner from "@components/Spinner/Spinner";
import { Breadcrumb } from "@components/Breadcrumb";
import ServerError from "@components/Error/ServerError";
import { getUser } from "@modules/Users/services/userCrud";
import { AlertCircleOutlineIcon } from "@components/icons";
import { updateUser } from "@modules/Users/services/userCrud";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Button, Col, Container, Row, Form } from "react-bootstrap";
import userFormModel from "@modules/Users/components/Form/FormModel/userFormModel";
import { validation } from "@modules/Users/components/Form/FormModel/validationSchema";
import {
  formInitialValues,
  formUpdateValues,
} from "@modules/Users/components/Form/FormModel/initialValues";

const NAME_LIMIT = 40;
const PHONE_LIMIT = 15;
const STREET_LIMIT = 255;
const CITY_LIMIT = 50;
const ZIP_LIMIT = 5;

const { formField } = userFormModel;

function ProfilePage() {
  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    zipCode,
    streetName,
    houseNumber,
    city,
    country,
  } = formField;
  const { t } = useTranslation();
  const [isAddressError, setIsAddressError] = useState(false);
  const { user_id } = useSelector((state) => state.auth.user);
  const queryClient = useQueryClient();

  const { data: user, isLoading } = useQuery(["user", user_id], () => getUser(user_id), {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!user_id,
  });

  const {
    isLoading: isFormLoading,
    isError,
    error,
    mutateAsync,
  } = useMutation("updateUser", updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries("users");
      queryClient.invalidateQueries(["user", user_id]);
    },
  });

  const updateExistUser = async (values) => {
    const areas = values.areas;
    let modifiedAreas = areas.map((area) =>
      Object.keys(area)
        .filter((key) => key.includes("id"))
        .reduce((obj, key) => {
          obj[key] = area[key];
          return obj;
        }, {})
    );

    let formData = {
      id: user_id,
      profile: {
        address: {},
        areas: modifiedAreas ? modifiedAreas : [],
      },
    };

    // add password key on form submission only when password input change
    if (values.password.length > 0) {
      formData["password"] = values.password;
    }

    const keys = Object.keys(formInitialValues);
    // eslint-disable-next-line array-callback-return
    keys.map((key) => {
      if (formUpdateValues[key] !== values[key]) {
        if (key === "phoneNumber" || key === "accountType") {
          formData.profile[key] = values[key];
        } else if (key === "first_name" || key === "last_name" || key === "email") {
          formData[key] = values[key];
        } else if (
          key == "streetName" ||
          key == "houseNumber" ||
          key == "zipCode" ||
          key == "city" ||
          key == "country"
        ) {
          if (
            values.streetName.length === 0 &&
            values.houseNumber.length === 0 &&
            values.zipCode.length === 0 &&
            values.city.length === 0 &&
            values.country.length === 0
          ) {
            setIsAddressError(false);
            mutateAsync(formData);
          } else if (
            values.streetName.length !== 0 &&
            values.houseNumber.length !== 0 &&
            values.zipCode.length !== 0 &&
            values.city.length !== 0 &&
            values.country.length !== 0
          ) {
            setIsAddressError(false);
            formData.profile.address[key] = values[key];
            mutateAsync(formData);
          } else {
            setIsAddressError(true);
          }
        }
      }
    });
  };

  if (isError) return <ServerError />;
  if (isLoading) return <Spinner height={"80vh"} />;
  return (
    <>
      <Breadcrumb title={`${t("profile")}`} />
      <Container>
        <Formik
          initialValues={formUpdateValues(user)}
          onSubmit={async (values) => {
            updateExistUser(values);
          }}
        >
          {({ handleSubmit, errors }) => {
            <p className={styles.validationError}>
              {Object.keys(errors).length > 0 && (
                <>
                  <AlertCircleOutlineIcon />
                  {t(errors.email) || t(errors.password)}
                </>
              )}
              {isError && error.response.data && error.response.data.profile && (
                <>
                  <AlertCircleOutlineIcon />
                  {t("validation.invalid_house_number") + " " + "(< 32767)"}
                </>
              )}
              {isAddressError && (
                <>
                  <AlertCircleOutlineIcon />
                  {t("validation.all_address_field_are_required")}
                </>
              )}
              {isError && error.response.data && error.response.data.email && (
                <>
                  <AlertCircleOutlineIcon />
                  {t("validation.user_with_this_email_already_exist")}
                </>
              )}
            </p>;
            return (
              <Form onSubmit={handleSubmit}>
                <Row className={styles.setting.section}>
                  <Col lg="2">
                    <h6 className="p-1 mb-0 ">{t("personal_data")}</h6>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="mb-2">
                      <Form.Label> {t(firstName.label)}</Form.Label>
                      <Field
                        type="text"
                        name={firstName.name}
                        className="form-control "
                        maxLength={NAME_LIMIT}
                        placeholder={t("maximum_of") + ` ${NAME_LIMIT} ` + t("characters")}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t(lastName.label)}</Form.Label>
                      <Field
                        type="text"
                        name={lastName.name}
                        className="form-control"
                        maxLength={NAME_LIMIT}
                        placeholder={t("maximum_of") + ` ${NAME_LIMIT} ` + t("characters")}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className={styles.setting.section}>
                  <Col lg="2">
                    <h6 className="p-1 mb-0 ">{t("login_credentials")}</h6>
                  </Col>
                  <Col lg="4">
                    <Form.Group className="mb-2">
                      <Form.Label> {t(email.label)}</Form.Label>
                      <Field
                        type="text"
                        name={email.name}
                        className={
                          errors && errors.email ? "form-control is-invalid" : "form-control "
                        }
                        validate={validation.validateEmail}
                      />
                    </Form.Group>
                    {/* <Form.Group>
											<Form.Label>{t(password.label)}</Form.Label>
											<Field
												autoComplete="off"
												type="password"
												name={password.name}
												className={"form-control"}
											/>
										</Form.Group> */}
                  </Col>
                </Row>
                <Row className={styles.setting.section}>
                  <Col lg="2">
                    <h6 className="p-1 mb-0 ">{t("profile")}</h6>
                  </Col>
                  <Col lg="4">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex align-item-center w-100 gap-2">
                        <Form.Group>
                          <Form.Label>{t(phoneNumber.label)}</Form.Label>
                          <Field
                            type="text"
                            name={phoneNumber.name}
                            className="form-control"
                            maxLength={PHONE_LIMIT}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.setting.section}>
                  <Col lg="2">
                    <h6 className="p-1 mb-0 ">{t("address")}</h6>
                  </Col>
                  <Col lg="4">
                    <div className="d-flex align-items-center gap-2">
                      <Form.Group className="flex-fill ">
                        <Form.Label>{t(streetName.label)}</Form.Label>
                        <Field
                          type="text"
                          name={streetName.name}
                          className="form-control mb-3"
                          maxLength={STREET_LIMIT}
                        />
                      </Form.Group>
                      <Form.Group className="flex-fill">
                        <Form.Label>{t(zipCode.label)}</Form.Label>

                        <Field
                          type="number"
                          name={zipCode.name}
                          className="form-control mb-3"
                          maxLength={ZIP_LIMIT}
                        />
                      </Form.Group>
                      <Form.Group className="flex-fill">
                        <Form.Label>{t(houseNumber.label)}</Form.Label>
                        <Field
                          type="number"
                          name={houseNumber.name}
                          className="form-control mb-3"
                          min="0"
                        />
                      </Form.Group>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                      <Form.Group className="flex-fill">
                        <Form.Label htmlFor="">{t(city.label)}</Form.Label>
                        <Field
                          type="text"
                          name={city.name}
                          className="form-control "
                          maxLength={CITY_LIMIT}
                        />
                      </Form.Group>
                      <Form.Group className="flex-fill">
                        <Form.Label>{t(country.label)}</Form.Label>
                        <Field
                          type="text"
                          name={country.name}
                          className="form-control "
                          maxLength={CITY_LIMIT}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
                <Row className={styles.setting.section}>
                  <Col lg="2"></Col>
                  <Col lg="4 ">
                    <div className="d-flex align-items-center justify-content-between">
                      <Button type="submit" className="btn  ms-auto" variant="outline-primary">
                        {isFormLoading ? t("button.saving") : t("button.save")}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Container>
      ;
    </>
  );
}

export default ProfilePage;
