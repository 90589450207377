import React from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function SensorType({ formField, setFieldValue, values, data }) {
  const { t } = useTranslation();
  const sensorTypes = [
    {
      name: "pulse_sensor",
    },
    {
      name: "soil_moisture_sensor",
    },
    {
      name: "soil_water_tension_sensor",
    },
    {
      name: "dendrometer_sensor",
    },
  ];
  const amountNodes = [
    {
      name: "I",
      value: 1,
    },
    {
      name: "III",
      value: 3,
    },
    {
      name: "IV",
      value: 4,
    },
  ];

  let selectedSensorType = values[formField] || "";
  if (!selectedSensorType && data) {
    if (data.soil_moisture_sensor !== undefined) selectedSensorType = "soil_moisture_sensor";
    else if (data.dendrometer_sensor !== undefined) selectedSensorType = "dendrometer_sensor";
    else if (data.soil_water_tension_sensor !== undefined)
      selectedSensorType = "soil_water_tension_sensor";
    else selectedSensorType = "pulse_sensor"; // Fallback default
  }

  // Ensure that the selected value is an object matching the `options`
  const selectedOption = sensorTypes
    .map((sensor) => ({ value: sensor.name, label: t(sensor.name) }))
    .find((sensor) => sensor.value === selectedSensorType);

  // Ensure that the selected amount of nodes is an object matching the `options`
  const selectedAmountNode = amountNodes
    .map((node) => ({ value: node.value, label: node.name }))
    .find((node) => node.value === values.amount_nodes);

  /*
  var defaultValue = "pulse_sensor";
  if (data) {
    if (data.soil_moisture_sensor != undefined) {
      defaultValue = "soil_moisture_sensor";
    } else if (data.dendrometer_sensor != undefined) {
      defaultValue = "dendrometer_sensor";
    } else if (data.soil_water_tension_sensor != undefined) {
      defaultValue = "soil_water_tension_sensor";
    }
  }*/

  return (
    <>
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>{t("sensor_type")}</Form.Label>
        {/* <Field type="text" name="name" className={"form-control mb-3"} /> */}
        <Select
          id={"sensorType"}
          type={"text"}
          value={selectedOption}
          onChange={(option) => {
            setFieldValue(formField, option.value);
          }}
          options={sensorTypes.map((sensorType) => ({
            value: sensorType.name,
            label: t(sensorType.name),
          }))}
        />
      </Form.Group>
      {["soil_moisture_sensor", "soil_water_tension_sensor"].includes(values["sensor_type"]) && (
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>{t("amount_nodes")}</Form.Label>
          {/* <Field type="text" name="name" className={"form-control mb-3"} /> */}
          <Select
            id={"amountNodes"}
            type={"text"}
            value={selectedAmountNode}
            onChange={(option) => {
              setFieldValue("amount_nodes", option.value);
            }}
            options={amountNodes.map((amountNode) => ({
              value: amountNode.value,
              label: amountNode.name,
            }))}
          />
        </Form.Group>
      )}
    </>
  );
}

export default SensorType;
