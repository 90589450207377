import React, { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { QrScanIcon } from "@components/icons";
import { useSelector } from "react-redux";
import { Html5Qrcode } from "html5-qrcode";

export default function QRForm({ data, setData }) {
  const { t } = useTranslation();
  const { mobile } = useSelector((state) => state.mobile);
  const isMobile = mobile || window.innerWidth <= 767;

  const [show, setShow] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanner, setScanner] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);

  useEffect(() => {
    if (show) {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices.length > 0) {
            setCameras(devices);
            setSelectedCamera(devices[0].id);
          }
        })
        .catch((err) => {
          console.error("Error fetching cameras:", err);
          setCameras([{ id: "default", label: "Laptop Camera" }]);
          setSelectedCamera("default");
        });
    } else {
      stopScanner();
    }
  }, [show]);

  const handleClose = async () => {
    await stopScanner();
    setShow(false);
  };

  const startScanner = async () => {
    if (!selectedCamera) {
      console.error("No camera selected!");
      return;
    }

    if (scanner) {
      await stopScanner();
    }

    const html5QrCode = new Html5Qrcode("qr-reader");
    setScanner(html5QrCode);

    try {
      await html5QrCode.start(
        selectedCamera,
        {
          fps: 10,
          qrbox: isMobile ? { width: 150, height: 150 } : { width: 250, height: 250 },
        },
        async (decodedText) => {
          console.log(`Scanned: ${decodedText}`);
          setData(decodedText);
          await stopScanner();
          setShow(false);
        },
        (error) => {
          console.warn(`Scanning error: ${error}`);
        }
      );
      setIsScanning(true);
    } catch (err) {
      console.error("Failed to start scanner", err);
    }
  };

  const stopScanner = async () => {
    if (scanner) {
      try {
        await scanner.stop();
        await scanner.clear();
        setScanner(null);
        setIsScanning(false);
      } catch (err) {
        console.error("Failed to stop scanner", err);
      }
    }
  };

  return (
    <div>
      <Button
        data-testid="qr-button"
        className="btn btn-primary"
        style={{ marginTop: "5px" }}
        onClick={() => setShow(true)}
      >
        <QrScanIcon title="QR Scanner" />
      </Button>

      <Modal show={show} onHide={handleClose} className="m-0">
        <Modal.Header closeButton>
          <Modal.Title>{t("QR Scanner")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 m-0">
          <Form.Group className="mb-3 text-center">
            <Form.Label>{t("Select Camera")}</Form.Label>
            <Form.Control
              as="select"
              value={selectedCamera}
              onChange={(e) => setSelectedCamera(e.target.value)}
            >
              {cameras.map((camera) => (
                <option key={camera.id} value={camera.id}>
                  {camera.label || `Camera ${camera.id}`}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <div
            id="qr-reader"
            style={{
              width: "100%",
              maxWidth: isMobile ? "100%" : "500px",
              margin: "auto",
              height: isMobile ? "100vh" : "300px",
              marginTop: "50px",
            }}
          ></div>
        </Modal.Body>

        <Modal.Footer>
          {!isScanning && (
            <Button variant="primary" onClick={startScanner}>
              {t("start_scanning")}
            </Button>
          )}
          {isScanning && (
            <Button
              variant="danger"
              onClick={stopScanner}
              style={{
                position: "relative",
                bottom: "0",
                left: "0",
                zIndex: 1000,
                padding: "10px 20px",
              }}
            >
              {t("stop_scanning")}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
