import styled from "styled-components";

import { GREEN, YELLOW, RED, GREY, BLUE, shadeColor, WHITE } from "@helper/colors";

const StatusButton = styled.span`
  display: block;
  padding: ${(props) => props.padding};
  border-radius: 3px;
  border: none;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  font-size: 11px;
  text-align: center;
  /* NOTE: if it there is check for a child tree, it needs to be checked for sensor as well  */
  ${(props) => {
    if (props?.status == 0) {
      const background = `linear-gradient(${GREEN}, ${shadeColor(GREEN, -15)})`;
      const color = "#fff";
      return `
			background: ${props.childTree ? background : background};
			color: ${props.childTree ? color : color};
        `;
    }
    if (props?.status === 1) {
      return `
				background:linear-gradient(${YELLOW}, ${shadeColor(YELLOW, -15)});;
				color:#fff;
        `;
    }
    if (props?.status === 2) {
      const background = `linear-gradient(${RED}, ${shadeColor(RED, -15)})`;
      const backgroundChild = `linear-gradient(${RED}, #fff)`;
      const color = "#fff";
      return `
			background: ${props.childTree ? background : background};
			color: ${props.childTree ? color : color};
        `;
    }
    if (props?.status === 3) {
      return `
			background:linear-gradient(${GREY}, ${shadeColor(GREY, -15)});
			color:#fff;
		`;
    }
    if (props?.status === 4) {
      return `
			background:linear-gradient(${WHITE}, ${shadeColor(WHITE, -15)});
			color:#fff;
		`;
    }
    if (props?.status === 5) {
      return `
			background:linear-gradient(${BLUE}, ${shadeColor(BLUE, -15)});
			color:#fff;
		`;
    }
  }}
`;

export default StatusButton;
