import { Field } from "formik";
import Select from "react-select";
import { Form } from "react-bootstrap";
import { useQuery } from "react-query";
import useChoices from "@hooks/useChoices";
import { useTranslation } from "react-i18next";
import { getAllIrrigationAreas } from "@modules/IrrigationArea/services/areaCrud";
import { useSelector } from "react-redux";

const PHONE_LIMIT = 15;

export default function UserProfile({ formField, setFieldValue, values }) {
  const { phoneNumber, accountType, areas } = formField;

  const { t } = useTranslation();
  // const [userType] = useChoices("user_type");
  const userType = [
    { key: 1, value: "admin" },
    { key: 2, value: "basic" },
  ];
  const { auth } = useSelector((state) => state);

  const userTypeAvailable = userType?.filter((type) => +type.key >= auth.user.account_type);

  const { data: areass } = useQuery("areas", getAllIrrigationAreas, {
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleChange = (event) => {
    setFieldValue("accountType", +event.target.value);
  };
  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-item-center w-100 gap-2">
        <Form.Group className="w-50">
          <Form.Label> {t(phoneNumber.label)}</Form.Label>
          <Field
            type="text"
            name={phoneNumber.name}
            className="form-control"
            maxLength={PHONE_LIMIT}
          />
        </Form.Group>
        <Form.Group className="w-50">
          <Form.Label> {t(accountType.label)}</Form.Label>
          <Field
            as="select"
            className="form-control "
            name={accountType.name}
            onChange={handleChange}
          >
            {userTypeAvailable?.map((userType) => (
              <option key={userType.key} value={userType.key}>
                {userType.value}
              </option>
            ))}
          </Field>
        </Form.Group>
      </div>
      {values.accountType === 2 && (
        <Form.Group className="w-100 ">
          <Form.Label> {t(areas.label)}</Form.Label>
          <Select
            id={"trees"}
            type={"text"}
            defaultValue={
              values.areas.length > 0
                ? values.areas?.map((area) => ({
                    value: area.id,
                    label: area.name,
                  }))
                : ""
            }
            onChange={(option) => {
              setFieldValue(
                `${areas.name}`,
                option?.map((area) => ({
                  id: area.value,
                }))
              );
            }}
            options={areass?.map((area) => ({
              value: area.id,
              label: area.name,
            }))}
            isMulti
          />
        </Form.Group>
      )}
    </div>
  );
}
