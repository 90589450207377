import React from "react";
import ReactEcharts from "echarts-for-react";

import { inverseTransform, kPaToPF, logTransform } from "./Gauge2";

import { colors, colorsSoilMoisture, colorsSoilWaterTension, colorsPulse } from "@helper/colors";
import { Button } from "react-bootstrap";

export default function MoistureGauge({
  tree,
  sensor,
  t,
  soilWaterTensionType,
  setSoilWaterTensionType,
}) {
  // const { t } = useTranslation();
  let COLORS = colors;
  let value = 0;
  let sensorType = "pulse";

  if (sensor) {
    sensorType = sensor.pulse_sensor
      ? "pulse"
      : sensor.soil_moisture_sensor
      ? "soil_moisture"
      : sensor.soil_water_tension_sensor
      ? "soil_water_tension"
      : "dendrometer";
    COLORS =
      sensorType == "soil_moisture"
        ? colorsSoilMoisture(
            tree.soil ? tree.soil.pwp : 10,
            tree.soil ? tree.soil.max_nf : 25,
            tree.soil ? tree.soil.max_f : 45
          )
        : sensorType == "soil_water_tension"
        ? colorsSoilWaterTension()
        : sensorType == "pulse"
        ? colorsPulse(tree.blue_threshold, tree.green_threshold, tree.yellow_threshold)
        : colors;
    // var color_values = COLORS.map((values) => {
    //   return kPaToPF(values[0]);
    // });
    if (sensorType == "soil_water_tension" && soilWaterTensionType == "pF") {
      COLORS = COLORS.map((values) => {
        return [values[0] == 0 ? 1 : 1 - kPaToPF(values[0]) / kPaToPF(-1585), values[1]];
      });
    }
    if (sensorType == "soil_water_tension" && soilWaterTensionType == "kPa") {
      COLORS = COLORS.map((values) => {
        return [logTransform(values[0], -2000, 0), values[1]];
      });
    }
    value =
      sensorType == "pulse"
        ? sensor.pulse_sensor.moisture_content
        : sensorType == "soil_moisture"
        ? sensor.soil_moisture_sensor.soil_moisture
        : sensorType == "soil_water_tension"
        ? sensor.soil_water_tension_sensor.soil_water_tension
        : 0;
  } else {
    value = tree.moisture_content;
  }

  // check for valid points
  if (sensorType == "soil_water_tension") {
    if (value == 255) {
      return (
        <h3>
          {t("unknown")} {t("status")}
        </h3>
      );
    }
  }
  const valueType = sensor
    ? sensorType == "pulse"
      ? "moisture_content"
      : sensorType == "soil_moisture"
      ? "soil_moisture"
      : sensorType == "soil_water_tension"
      ? "soil_water_tension"
      : ""
    : "moisture_content";
  let valueUnit = "%";
  let max = 100;
  let min = 0;

  if (soilWaterTensionType == "kPa") {
    min = sensorType == "soil_water_tension" ? 0 : 0;
    max = sensorType == "soil_water_tension" ? 1 : 100;
    valueUnit = sensorType == "soil_water_tension" ? "kPa" : "%";
  } else {
    min = sensorType == "soil_water_tension" ? 4.2 : 0;
    max = sensorType == "soil_water_tension" ? 0 : 100;
    valueUnit = sensorType == "soil_water_tension" ? "pF" : "%";
  }
  const title = `${t(valueType)} in ${valueUnit}`;
  const option = {
    title: {
      text: title,
      // top: "bottom",
      // top: "-2%",
      // padding: 50,
      show: true,
      x: "center",
      // y: "bottom",
      y: "top",
    },
    series: [
      {
        type: "gauge",
        min: min,
        max: max,
        radius: "80%",
        center: ["50%", "50%"],
        axisLine: {
          lineStyle: {
            width: 20,
            color: COLORS,
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          // show: false,
          formatter: function (value) {
            // Convert back to the original value for display
            let realValue = value;
            if (sensorType == "soil_water_tension") {
              if (soilWaterTensionType == "kPa") {
                realValue = inverseTransform(value, -2000, 0).toFixed(0);
              } else {
                Math.max(kPaToPF(value).toFixed(1), 0);
              }
              // realValue = Math.log10(-value).toFixed(0);
              // realValue = kPaToPF(value);
              // realValue = pFToKPa(value);
              // console.log("AXIS", realValue, value);
            }
            // const realValue = Math.pow(max - min + 1, value) + min - 1;
            // return `${realValue} ${valueUnit}`;
            // console.log("AXIS", realValue, value);
            return `${realValue}`;
          },
        },
        pointer: {
          length: 100,
        },
        detail: {
          show: true,
          formatter: function (value) {
            // Convert back to the original value for display
            let realValue = value;
            if (sensorType == "soil_water_tension") {
              if (soilWaterTensionType == "kPa") {
                realValue = inverseTransform(value, -2000, 0).toFixed(0);
              } else {
                Math.max(kPaToPF(value).toFixed(1), 0);
              }
            }
            // const realValue = Math.pow(max - min + 1, value) + min - 1;
            // return `${realValue} ${valueUnit}`;
            return realValue;
          },
        },
        data: [
          {
            value:
              soilWaterTensionType == "kPa"
                ? sensorType == "soil_water_tension"
                  ? logTransform(value, -2000, 0)
                  : value
                : sensorType == "soil_water_tension"
                ? Math.max(kPaToPF(value).toFixed(1), 0)
                : value.toFixed(0),
            // name: `${t(valueType)}`,
          },
        ],
      },
    ],
  };
  const style = {
    height: "280px",
    width: "100%",
  };
  const handleClick = () => {
    if (soilWaterTensionType != "pF") {
      setSoilWaterTensionType("pF");
    } else {
      setSoilWaterTensionType("kPa");
    }
  };

  return (
    <div>
      {sensorType == "soil_water_tension" && (
        <Button onClick={handleClick}>{soilWaterTensionType == "kPa" ? "pF" : "kPa"}</Button>
      )}
      <ReactEcharts option={option} style={style} notMerge />
    </div>
  );
}

export function Gauge({ value, min, max, COLORS }) {
  const option = {
    series: [
      {
        type: "gauge",
        // startAngle: 180,
        // endAngle: 0,
        min: min,
        max: max,
        radius: "90%",
        center: ["50%", "50%"],
        axisLine: {
          lineStyle: {
            width: 20,
            color: COLORS,
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        pointer: {
          length: 50,
        },
        detail: {
          show: true,
        },
        data: [
          {
            value: value,
          },
        ],
      },
    ],
  };
  return <ReactEcharts option={option} style={{ height: "120%", width: "120%" }} notMerge />;
}
