import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styles } from "@components/styles";
import { useTranslation } from "react-i18next";
import { Hardware, Name, Threshold, SensorType, NetworkType, Installation } from "./steps";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { validation } from "./FormModel/validationSchema";
import { useMutation, useQueryClient } from "react-query";
//import FormError from "@components/Form/FormError";
import { createSensor, updateSensor } from "../../services/sensorCrud";
import { Button, Form, Row, Col, Modal, ModalBody } from "react-bootstrap";
import { formInitialValues, formUpdateValues } from "./FormModel/initialValues";
import { AlertCircleOutlineIcon } from "@components/icons";

const steps = [
  "sensor_form.network_type",
  "sensor_form.sensor_type",
  "sensor_form.hardware_serial",
  "sensor_form.name",
];
const updateSteps = [
  "sensor_form.network_type",
  "sensor_form.sensor_type",
  "sensor_form.hardware_serial",
  "sensor_form.name",
  "sensor_form.threshold",
];

//form steps for creating new sensor
const renderSteps = (activeStep, setData, data, setFieldValue, values, setFormValues) => {
  const { t } = useTranslation();
  switch (activeStep) {
    case 0:
      return (
        <NetworkType
          formField={"network_type"}
          values={values}
          setFieldValue={(field, value) => {
            setFieldValue(field, value);
            setFormValues((prevValues) => {
              return { ...prevValues, [field]: value };
            });
          }}
        />
      );
    case 1:
      return (
        <SensorType
          formField={"sensor_type"}
          values={values}
          setFieldValue={(field, value) => {
            setFieldValue(field, value);
            setFormValues((prevValues) => {
              return { ...prevValues, [field]: value };
            });
          }}
        />
      );
    case 2:
      return (
        <Hardware
          formField={"hardware_serial"}
          setData={setData}
          validation={validation}
          values={values}
          data={data}
        />
      );
    case 3:
      return <Name formField={"name"} />;
    case 4:
      return (
        <Installation
          formField={"sensor_type"}
          setFieldValue={setFieldValue}
          values={values}
          data={data}
        />
      );

    default:
      return <>{t("not_found")}</>;
  }
};

//form steps for updating sensor
const renderUpdateSteps = (activeStep, isUpdate, setData, data, setFieldValue, detail, values) => {
  const { t } = useTranslation();
  switch (activeStep) {
    case 0:
      return (
        <NetworkType
          formField={"network_type"}
          setFieldValue={setFieldValue}
          values={values}
          data={detail}
        />
      );
    case 1:
      return (
        <SensorType
          formField={"sensor_type"}
          setFieldValue={setFieldValue}
          values={values}
          data={detail}
        />
      );
    case 2:
      return (
        <Hardware
          formField={"hardware_serial"}
          setData={setData}
          validation={validation}
          values={values}
          data={data}
        />
      );
    case 3:
      return <Name formField={"name"} />;
    case 4:
      return <Threshold formField={"threshold"} />;

    default:
      return <>{t("not_found")}</>;
  }
};

export default function SensorForm({
  isUpdate,
  detail,
  setIsUpdate,
  setOpenForm,
  show,
  setSensorValues,
}) {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isAddressError, setIsAddressError] = useState(false);
  const [data, setData] = useState("");
  const [showTreeForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState(
    isUpdate ? formUpdateValues(detail) : formInitialValues(data)
  );

  const isLastStep = isUpdate
    ? activeStep === updateSteps.length - 1
    : activeStep === steps.length - 1;

  const handleClose = () => {
    setOpenForm(false);
    setIsUpdate && setIsUpdate(false);
    setActiveStep(0);
  };

  const queryClient = useQueryClient();

  const { isLoading, isError, error, mutateAsync } = useMutation(
    isUpdate ? "updateSensor" : "createSensor",
    isUpdate ? updateSensor : createSensor,
    {
      onSuccess: (responseData) => {
        queryClient.invalidateQueries("sensors");
        queryClient.invalidateQueries(["sensors", id]);
        setSensorValues ? setSensorValues(responseData.data) : [];
        handleClose();
      },

      onError: (error) => {
        if (error.response.data) {
          setIsAddressError(false);
        }
      },
    }
  );

  const handleSubmit = async (values) => {
    setFormValues(values);

    const formData = {
      hardware_serial: values.hardware_serial,
      name: values.name,
      sensor_type: values.sensor_type,
      network_type: values.network_type,
      amount_nodes: values.amount_nodes,
    };
    values.imei ? (formData["imei"] = values.imei) : "";
    const updateFormData = {
      hardware_serial: values.hardware_serial,
      name: values.name,
      threshold: values.threshold,
      sensor_type: values.sensor_type,
      network_type: values.network_type,
    };
    values.imei ? (updateFormData["imei"] = values.imei) : "";
    !isLastStep && setActiveStep(activeStep + 1);

    if (isLastStep) {
      isUpdate ? await mutateAsync({ updateFormData, detail }) : await mutateAsync(formData);
      !isLastStep && setActiveStep(activeStep + 1);
    }
  };

  useEffect(() => {
    setFormValues(formValues);
  }, [formValues]);

  const getErrorMessage = (error) => {
    if (error.response.data) {
      // Adjust this based on your actual error response structure
      if (Array.isArray(error.response.data.non_field_errors)) {
        return error.response.data.non_field_errors.join(" ");
      }
      if (error.response.data.detail) {
        return error.response.data.detail;
      }
      return Object.values(error.response.data).flat().join(" ");
    }
    return null;
  };

  return (
    <Row>
      <Col lg={12}>
        <Modal
          data-testid="sensor-form-modal"
          onHide={handleClose}
          show={show}
          centered
          style={{
            visibility: `${showTreeForm ? "hidden" : "visible"}`,
          }}
        >
          <ModalHeader closeButton>
            <h5 className="mb-0">{isUpdate ? `${t("edit_sensor")}` : `${t("add_sensor")}`}</h5>
          </ModalHeader>
          <ModalBody>
            <div className={styles.wizardList}>
              {isUpdate ? (
                <>
                  {updateSteps.map((step, i) => (
                    <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                      {t(step)}
                    </span>
                  ))}
                </>
              ) : (
                <>
                  {steps.map((step, i) => (
                    <span key={step} className={activeStep == i ? "wizard-active" : ""}>
                      {t(step)}
                    </span>
                  ))}
                </>
              )}
            </div>
            <Formik
              initialValues={formValues}
              enableReinitialize={false}
              onSubmit={async (values) => {
                handleSubmit(values);
              }}
            >
              {({ handleSubmit, values, setFieldValue, errors }) => {
                useEffect(() => {
                  setFormValues(values);
                }, [values]);

                return (
                  <>
                    <p className={styles.validationError}>
                      {Object.keys(errors).length > 0 && (
                        <>
                          <AlertCircleOutlineIcon />
                          {t(errors.hardware_serial)}
                        </>
                      )}
                      {isError &&
                        error.response.data &&
                        getErrorMessage(error) === "Sensor limit exceeded for your package" && (
                          <>
                            <AlertCircleOutlineIcon />
                            {t("validation.sensor_limit_exceeded_for_your_package")}
                          </>
                        )}
                    </p>
                    <Form onSubmit={handleSubmit}>
                      <div className="form-container">
                        {isUpdate
                          ? renderUpdateSteps(
                              activeStep,
                              isUpdate,
                              setData,
                              data,
                              setFieldValue,
                              detail,
                              values,
                              setFormValues
                            )
                          : renderSteps(
                              activeStep,
                              setData,
                              data,
                              setFieldValue,
                              values,
                              setFormValues
                            )}
                      </div>
                      <div className={styles.formBody}>
                        {activeStep !== 0 && (
                          <Button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => setActiveStep((step) => step - 1)}
                          >
                            {t("button.back")}
                          </Button>
                        )}
                        <button type="submit" className="btn btn-outline-primary ms-auto">
                          {isLastStep
                            ? isUpdate
                              ? isLoading
                                ? t("button.updating")
                                : t("button.update")
                              : isLoading
                              ? t("button.saving")
                              : t("button.save")
                            : t("button.next")}
                        </button>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
      </Col>
    </Row>
  );
}
