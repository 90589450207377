import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, Button } from "react-bootstrap";

import useChoices from "@hooks/useChoices";
import {
  colorsPulse,
  colorsSoilMoisture,
  colorsSoilWaterTension,
  GREY,
  shadeColor,
  findClosest,
} from "@helper/colors";
import MoistureGauge from "./MoistureGauge";
import { SensorPlot } from "@modules/Sensors/components/SensorDetail";
import { ClipboardDataIcon } from "../../../components/icons";
// import { Button } from "bootstrap";
// import Button from "@components/Button/Button";
function treeStateDescription(t, healthState, healthStateNames) {
  const healthStateName = healthStateNames ? healthStateNames[healthState].value : healthState;
  var desc = `Der Baum ist ${t(healthStateName)}.`;
  switch (healthState) {
    case 0:
      desc = desc + " Sie brauchen nichts weiter tun.";
      break;
    case 1:
      desc = desc + " Es ist sinnvoll den Baum zu bewässern.";
      break;
    case 2:
      desc = desc + " Sie müssen den Baum bewässern oder kontrollieren.";
      break;
    case 3:
      desc = desc + " Kontrollieren Sie den Sensor.";
      break;
    default:
      desc = desc + " Beobachten Sie den Baum.";
  }
  return desc;
}

const Tree = (args) => {
  const { t } = useTranslation();
  const tree = args.tree;
  const [plotType, setPlotType] = useState("resistance");
  const [selectedInstallation, setSelectedInstallation] = useState(null);
  const [showData, setShowData] = useState(false);
  const [soilWaterTensionType, setSoilWaterTensionType] = useState("kPa");
  const installations = tree.installations;
  useEffect(() => setSelectedInstallation(null), [tree]); // necessary to unset Sensor if Tree changes

  // SOIL
  function getDepthColor(depth) {
    let soilColor = GREY;
    const soilInstallationsDepth = installations.filter(
      (installation) =>
        installation.sensor &&
        (installation.sensor.soil_moisture_sensor !== null ||
          installation.sensor.soil_water_tension_sensor !== null) &&
        installation.depth == depth
    );
    if (soilInstallationsDepth.length == 0) {
      return GREY;
    }
    const sensor = soilInstallationsDepth[0].sensor;
    let soilColors = colorsSoilWaterTension();
    let soilState = "UN";
    let value = 0;
    if (sensor.soil_moisture_sensor !== null) {
      soilColors = colorsSoilMoisture(
        tree.soil ? tree.soil.pwp : 10,
        tree.soil ? tree.soil.max_nf : 25,
        tree.soil ? tree.soil.max_f : 45
      );
      value = sensor.soil_moisture_sensor.soil_moisture / 100;
      soilState = sensor.soil_moisture_sensor.soil_state;
    } else {
      value = sensor.soil_water_tension_sensor.soil_water_tension;
      soilState = sensor.soil_water_tension_sensor.soil_state;
    }
    if (soilState != "UN") {
      const closestPair = findClosest(value, soilColors);
      soilColor = closestPair[1];
    }
    return soilColor;
  }
  const crownInstallations = installations.filter(
    (installation) => installation.sensor && installation.sensor.pulse_sensor !== null // installation.removed_at < new Date()
  );
  const soilInstallations = installations.filter(
    (installation) =>
      installation.sensor &&
      (installation.sensor.soil_moisture_sensor !== null ||
        installation.sensor.soil_water_tension_sensor !== null)
  );
  const hasBall = (items) => {
    return items.some((item) => item.depth === 0);
  };

  const soilHasBall = hasBall(soilInstallations);
  const depths = soilInstallations.map((installation) => installation.depth);
  const depthsSet = new Set(depths);
  let soilColorBall = GREY;
  let soilColor30 = GREY;
  let soilColor60 = GREY;
  let soilColor90 = GREY;
  if (depthsSet.size == 1) {
    const sensor = soilInstallations[0].sensor;
    let soilColors30 = colorsSoilWaterTension();
    let soilState = "UN";
    let value = 0;
    if (sensor.soil_moisture_sensor !== null) {
      soilColors30 = colorsSoilMoisture(
        tree.soil ? tree.soil.pwp : 10,
        tree.soil ? tree.soil.max_nf : 25,
        tree.soil ? tree.soil.max_f : 45
      );
      value = sensor.soil_moisture_sensor.soil_moisture / 100;
      soilState = sensor.soil_moisture_sensor.soil_state;
    } else {
      value = sensor.soil_water_tension_sensor.soil_water_tension;
      soilState = sensor.soil_water_tension_sensor.soil_state;
    }
    if (soilState != "UN") {
      const closestPair = findClosest(value, soilColors30);
      soilColor30 = closestPair[1];
    }
  } else if (depthsSet.size > 1) {
    soilColor30 = getDepthColor(30);
    soilColor60 = getDepthColor(60);
    soilColor90 = getDepthColor(90);
  }
  if (soilHasBall) {
    soilColorBall = getDepthColor(0);
  }

  const treeIconHeight = depthsSet.size > 1 ? 310 : 250;
  const handleSensorClick = (installation) => {
    selectedInstallation ? setSelectedInstallation(null) : setSelectedInstallation(installation);
  };
  const healthState = tree.health_state;
  function calculateSensorYPosition(percentage) {
    const trunkStartY = 0; // Top Y of the trunk
    const trunkHeight = 230; // Height of the trunk
    return trunkStartY + trunkHeight * ((100 - percentage) / 100); // Adjusting for 50% height
  }

  function calculateSensorXPosition(index) {
    const trunkStartX = 116; // Starting X of the trunk
    const trunkWidth = 0;
    const position =
      trunkStartX + trunkWidth / 2 + parseInt((index + 1) / 2) * 45 * (-1) ** (index % 2); // If you have the trunk width, adjust accordingly
    return position;
  }
  const findSmallestMoistureContent = (installations) => {
    // Use `Infinity` as the initial smallest value so that any value will be smaller
    let smallestMoistureContent = Infinity;

    installations.forEach((installation) => {
      const { moisture_content } = installation.sensor.pulse_sensor;
      if (moisture_content < smallestMoistureContent) {
        smallestMoistureContent = moisture_content;
      }
    });

    return smallestMoistureContent;
  };

  let fillCrownColor = GREY; // unknown
  if (
    // healthState != 3 &&
    crownInstallations.some((installation) => [0, 1].includes(installation.sensor.sensor_state))
  ) {
    const moistureContent = findSmallestMoistureContent(crownInstallations);
    const closestPair = findClosest(
      moistureContent / 100,
      colorsPulse(tree.blue_threshold, tree.green_threshold, tree.yellow_treshold)
    );
    fillCrownColor = closestPair[1];
    // crownInstallations.fillCrownColor = closestPair[1];
  }
  const fillCrown2Color = shadeColor(fillCrownColor, -18);

  const [healthStateNames] = useChoices("dry_state");

  const SensorIcon = ({ onSensorClick, installation, installations }) => {
    const sensor = installation.sensor;
    const sensorType = sensor.pulse_sensor
      ? "pulse"
      : sensor.soil_moisture_sensor
      ? "soil_moisture"
      : sensor.soil_water_tension_sensor
      ? "soil_water_tension"
      : "dendrometer";
    let index = 0;
    if (sensorType == "pulse") {
      const pulseInstallations = installations.filter(
        (installation) => installation.sensor && installation.sensor.pulse_sensor !== null
      );
      index = pulseInstallations.indexOf(installation);
    }
    function getColor(sensorType, sensor) {
      let color = GREY;
      if (sensorType == "soil_moisture") {
        const soilState = sensor.soil_moisture_sensor.soil_state;
        const soilMoisture = sensor.soil_moisture_sensor.soil_moisture;
        const tree = installation.tree;
        const soilColors = colorsSoilMoisture(
          tree.soil ? tree.soil.pwp : 10,
          tree.soil ? tree.soil.max_nf : 25,
          tree.soil ? tree.soil.max_f : 45
        );
        if (soilState != "UN") {
          const closestPair = findClosest(soilMoisture / 100, soilColors);
          color = closestPair[1];
        }
      } else if (sensorType == "soil_water_tension") {
        const soilState = sensor.soil_water_tension_sensor.soil_state;
        const soilWaterTension = sensor.soil_water_tension_sensor.soil_water_tension;
        if (soilState != "UN") {
          const closestPair = findClosest(soilWaterTension, colorsSoilWaterTension());
          color = closestPair[1];
        }
      } else if (sensorType == "pulse") {
        if (![2, 3, 4].includes(sensor.sensor_state)) {
          // not unknown
          const moistureContent = sensor.pulse_sensor.moisture_content;
          const closestPair = findClosest(
            moistureContent / 100,
            colorsPulse(tree.blue_threshold, tree.green_threshold, tree.yellow_treshold)
          );
          color = closestPair[1];
        }
      }
      return color;
    }
    const fillColor = shadeColor(getColor(sensorType, sensor), -30);
    const Icon = (
      <svg
        x={
          ["soil_moisture", "soil_water_tension"].includes(sensorType) &&
          soilHasBall &&
          installation.depth != 0
            ? calculateSensorXPosition(index + 3)
            : calculateSensorXPosition(index)
        }
        y={calculateSensorYPosition(
          ["soil_moisture", "soil_water_tension"].includes(sensorType)
            ? installation.depth == 0
              ? -5
              : depthsSet.size > 1
              ? (30 - installation.depth) / 3
              : 0
            : tree.height && installation.height
            ? (installation.height / tree.height) * 100
            : 70
        )}
        width="10%"
        height="10%"
        key={installation.id}
      >
        <svg
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
          onClick={onSensorClick}
          style={{
            cursor: "pointer",
            fill: "none",
            stroke: getColor(sensorType, sensor),
          }}
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <circle cx="256" cy="256" r="240" fill="#EEEEEEEE" />{" "}
            <g>
              {" "}
              <g>
                {" "}
                <g>
                  {" "}
                  <path
                    clipRule="evenodd"
                    d="M415.698,192.641c-8.379-21.068-21.236-40.268-38.577-57.6 l36.309-36.364c26.971,26.989,45.555,57.456,55.745,91.404c6.226,20.683,9.349,42.655,9.367,65.919v0.06 c-0.008,34.011-6.66,65.254-19.957,93.729c-10.701,23.024-25.735,44.24-45.097,63.651l-36.427-36.422 c13.297-13.305,23.948-27.696,31.945-43.171c12.119-23.434,18.17-49.363,18.156-77.788V256 C427.19,233.287,423.372,212.168,415.698,192.641z"
                    fillRule="evenodd"
                    fill={fillColor}
                  ></path>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <path
                    clipRule="evenodd"
                    d="M351.69,160.99c8.768,8.825,16.062,18.27,21.88,28.334h0.06 c11.449,19.798,17.17,41.983,17.165,66.56V256c-0.004,25.516-6.152,48.478-18.447,68.889h-0.057 c-5.676,9.334-12.638,18.139-20.892,26.414v-0.06l-0.057,0.06l-36.427-36.423c2.371-2.448,4.562-4.95,6.576-7.505v-0.06 c11.828-14.672,17.763-31.779,17.807-51.315v-0.116c-0.019-22.938-8.124-42.506-24.323-58.705l36.483-36.538 C351.541,160.752,351.618,160.868,351.69,160.99z"
                    fill={fillColor}
                    fillRule="evenodd"
                  ></path>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <path
                    clipRule="evenodd"
                    d="M225.829,225.571c8.342-8.35,18.427-12.519,30.259-12.509 c11.851-0.01,21.956,4.159,30.316,12.509l0.059,0.059c8.313,8.348,12.463,18.433,12.451,30.254V256 c-0.016,11.798-4.187,21.863-12.51,30.197c-0.03,0.027-0.048,0.068-0.058,0.116c-8.364,8.272-18.447,12.402-30.259,12.394 c-11.817,0.009-21.884-4.142-30.201-12.452l-0.058-0.058c-8.311-8.317-12.481-18.365-12.511-30.138c0-0.06,0-0.117,0-0.176 c-0.012-11.787,4.121-21.854,12.395-30.196C225.747,225.653,225.786,225.615,225.829,225.571z"
                    fill={fillColor}
                    fillRule="evenodd"
                    // transform="scale(2)"
                  ></path>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <path
                    clipRule="evenodd"
                    d="M225.829,225.571c8.342-8.35,18.427-12.519,30.259-12.509 c11.851-0.01,21.956,4.159,30.316,12.509l0.059,0.059c8.313,8.348,12.463,18.433,12.451,30.254V256 c-0.016,11.798-4.187,21.863-12.51,30.197c-0.03,0.027-0.048,0.068-0.058,0.116c-8.364,8.272-18.447,12.402-30.259,12.394 c-11.817,0.009-21.884-4.142-30.201-12.452l-0.058-0.058c-8.311-8.317-12.481-18.365-12.511-30.138c0-0.06,0-0.117,0-0.176 c-0.012-11.787,4.121-21.854,12.395-30.196C225.747,225.653,225.786,225.615,225.829,225.571z"
                    fill={fillColor}
                    fillRule="evenodd"
                  ></path>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <path
                    clipRule="evenodd"
                    d="M121.265,255.884c-0.006-1.646,0.014-3.273,0.059-4.887 c1.113-35.124,14.225-65.242,39.335-90.356l36.426,36.422c-16.217,16.235-24.306,35.842-24.265,58.821c0,0.059,0,0.116,0,0.176 c-0.015,2.561,0.083,5.084,0.291,7.563v0.057c1.61,19.688,9.602,36.736,23.974,51.143c0.035,0.037,0.073,0.075,0.117,0.116 l-36.485,36.423l-0.058-0.059c-26.237-26.289-39.368-58.037-39.394-95.243C121.265,256,121.265,255.942,121.265,255.884z"
                    fill={fillColor}
                    fillRule="evenodd"
                  ></path>{" "}
                </g>{" "}
                <g>
                  {" "}
                  <path
                    clipRule="evenodd"
                    d="M35.437,225.28c6.121-48.44,27.186-90.661,63.192-126.662 l36.425,36.423c-29.26,29.239-45.727,63.78-49.402,103.621c-0.51,5.681-0.763,11.479-0.756,17.397 c0.002,47.206,16.684,87.524,50.042,120.959l0.058,0.059l-36.426,36.421l-0.058-0.058 c-43.359-43.459-65.025-95.92-64.997-157.381C33.521,245.532,34.161,235.271,35.437,225.28z"
                    fill={fillColor}
                    fillRule="evenodd"
                  ></path>{" "}
                </g>{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>
      </svg>
    );
    return Icon;
  };
  const { mobile } = useSelector((state) => state.mobile);
  const getHealthStateCrown = (sensor) => {
    if ([2, 3, 4].includes(sensor.sensor_state)) {
      return 3;
    }
    const moistureContent = sensor?.pulse_sensor?.moisture_content;
    if (moistureContent > tree.blue_threshold) {
      return 5;
    }
    if (moistureContent > tree.green_threshold) {
      return 0;
    }
    if (moistureContent > tree.yellow_treshold) {
      return 1;
    }
    return 2;
  };
  // const selectedInstallationHealthState = (sensor) => {
  //   return getHealthStateCrown(sensor) : 3;
  // };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        alignItems: mobile ? "flex-start" : "center",
        // height: mobile && selectedInstallation ? `${treeIconHeight + 30}px` : `${treeIconHeight}px`,
        height: "auto",
        width: "100%",
        // justifyContent: "center",
      }}
    >
      {!mobile && (
        <p
          style={{
            height: "250px",
            width: "47%",
          }}
          className="leftOfTree"
        >
          <div style={{ display: "flex", alignItems: "start" }}>
            {selectedInstallation &&
              treeStateDescription(
                t,
                selectedInstallation.sensor.pulse_sensor
                  ? getHealthStateCrown(selectedInstallation.sensor)
                  : 3,
                healthStateNames
              )}
            {selectedInstallation && (
              <Button onClick={() => setShowData(true)}>
                <ClipboardDataIcon />
              </Button>
            )}
          </div>
          {selectedInstallation &&
            getHealthStateCrown(selectedInstallation.sensor) != 3 &&
            MoistureGauge({
              tree: tree,
              sensor: selectedInstallation.sensor,
              t: t,
              soilWaterTensionType: soilWaterTensionType,
              setSoilWaterTensionType: setSoilWaterTensionType,
            })}
        </p>
      )}
      <>
        <svg
          width="250px"
          height={`${treeIconHeight}px`}
          viewBox={`0 0 256 ${treeIconHeight + 6}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            style={{
              stroke: "none",
              strokeWidth: 0,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              fill: "none",
              fillRule: "nonzero",
              opacity: 1,
            }}
            transform="matrix(2.740322, 0, 0, 2.543772, 4.580997, 2.333565)"
          >
            {" "}
            <path
              id="crown1"
              path
              d="M 51.865 1.523 c 2.639 0.961 4.659 2.757 6.26 5.115 c 5.117 -0.776 10.301 0.912 13.976 4.587 c 3.675 3.675 5.364 8.857 4.588 13.975 c 4.168 3.07 6.638 7.929 6.638 13.126 c 0 5.393 -2.644 10.387 -7.091 13.437 c -0.76 2.672 -2.184 5.104 -4.135 7.055 c -3.134 3.134 -7.323 4.754 -11.539 4.754 c -3.088 0 -6.19 -0.869 -8.902 -2.649 l 0.549 -0.836 l -10.447 -1.312 c 0 0 0 0 0 0 l -0.216 0.977 c -0.422 -0.094 -0.843 -0.204 -1.256 -0.329 c -3.807 3.412 -8.875 4.69 -13.641 3.882 c 0.009 -0.082 0.048 -0.316 0.054 -0.379 C 21.64 59.31 16.967 51.451 16.924 45 c -0.041 -6.216 2.696 -12.469 7.394 -16.172 c -0.92 -6.117 1.123 -11.997 4.951 -16.687 c 3.961 -4.852 10.438 -6.889 16.416 -5.562 C 45.933 3.689 48.897 2.544 51.865 1.523"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: fillCrownColor,
                fillRule: "nonzero",
                text: { display: "none" },
                "hover text": { display: "block" },
                opacity: 1,
              }}
              transform=" matrix(1 0 0 1 0 0) "
              strokeLinecap="round"
            />
            ,
            <path
              id="crown2"
              path
              d="M 26.702 62.925 C 21.64 59.31 17.809 51.551 17.767 45.1 c -0.041 -6.216 2.718 -12.047 7.415 -15.75 c -0.92 -6.117 0.952 -12.328 5.086 -16.751 c 4.133 -4.423 9.991 -6.482 15.793 -5.591 c 1.564 -2.275 3.552 -4.128 5.804 -5.483 C 49.745 0.537 47.41 0 44.999 0 c -5.197 0 -10.056 2.47 -13.126 6.639 c -5.117 -0.777 -10.3 0.912 -13.975 4.587 s -5.364 8.857 -4.587 13.975 c -4.169 3.07 -6.638 7.929 -6.638 13.126 c 0 5.393 2.644 10.387 7.091 13.437 c 0.759 2.67 2.183 5.103 4.135 7.055 c 2.456 2.456 5.534 3.942 8.749 4.487 C 26.656 63.223 26.696 62.987 26.702 62.925 z"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: fillCrown2Color,
                fillRule: "nonzero",
                opacity: 1,
              }}
              transform=" matrix(1 0 0 1 0 0) "
              strokeLinecap="round"
            />{" "}
            <path
              id="trunk"
              path
              d="M 57.523 54.976 l -3.031 -2.947 c -0.387 -0.375 -0.999 -0.378 -1.388 -0.007 l -3.865 3.69 V 40.889 c 0 -0.552 -0.447 -1 -1 -1 h -4.275 c -0.552 0 -1 0.448 -1 1 v 7.186 l -4.745 -4.919 c -0.377 -0.391 -1.063 -0.391 -1.439 0 l -2.52 2.614 c -0.369 0.382 -0.374 0.986 -0.013 1.375 l 6.515 7.007 V 89 c 0 0.553 0.448 1 1 1 h 6.477 c 0.553 0 1 -0.447 1 -1 V 64.29 l 8.276 -7.872 c 0.197 -0.188 0.309 -0.447 0.311 -0.719 C 57.828 55.426 57.718 55.166 57.523 54.976 z"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: "rgb(160,126,99)",
                fillRule: "nonzero",
                opacity: 1,
              }}
              transform=" matrix(1 0 0 1 0 0) "
              strokeLinecap="round"
            />{" "}
          </g>{" "}
          <rect
            id="soil30"
            // rect
            x="12.128"
            y="230.382"
            width="230.817"
            height="25"
            style={{ fill: soilColor30 }}
            rx="2.961"
            ry="2.961"
          />
          {depthsSet.size > 1 && (
            <>
              <rect
                id="soil60"
                // rect
                x="12.128"
                y="255.382"
                width="230.817"
                height="25"
                style={{ fill: soilColor60 }}
                rx="2.961"
                ry="2.961"
              />
              <rect
                id="soil90"
                // rect
                x="12.128"
                y="280.382"
                width="230.817"
                height="25"
                style={{ fill: soilColor90 }}
                rx="2.961"
                ry="2.961"
              />
            </>
          )}
          {soilHasBall && (
            <rect
              id="soilRootBall"
              // rect
              x="77.128"
              y="230.382"
              width="100.817"
              height="50"
              style={{ fill: soilColorBall }}
              rx="20"
              ry="20"
            />
          )}
          {installations.map(
            (installation) =>
              !installation.removed_at && (
                <SensorIcon
                  onSensorClick={() => {
                    // tree.health_state != 3
                    //?
                    handleSensorClick(installation);
                    // : () => {
                    //     console.log("Health State unknown");
                    //   };
                  }}
                  key={installation.id}
                  installation={installation}
                  installations={installations}
                />
              )
          )}
        </svg>
        {mobile && selectedInstallation && (
          <div
            style={{
              width: "100%",
              padding: "10px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {treeStateDescription(
                t,
                selectedInstallation.sensor.pulse_sensor
                  ? getHealthStateCrown(selectedInstallation.sensor)
                  : 3,
                healthStateNames
              )}
              <Button onClick={() => setShowData(true)}>
                <ClipboardDataIcon />
              </Button>
            </div>
            {selectedInstallation.tree.health_state != 3 &&
              MoistureGauge({
                tree: tree,
                sensor: selectedInstallation.sensor,
                t: t,
                soilWaterTensionType: soilWaterTensionType,
                setSoilWaterTensionType: setSoilWaterTensionType,
              })}
          </div>
        )}

        {/* Modal Component */}
        {selectedInstallation && showData && (
          <Modal
            onHide={() => {
              setShowData(false);
            }}
            show={!!selectedInstallation}
            centered
          >
            <ModalHeader closeButton>
              <Modal.Title>{selectedInstallation.sensor.name}</Modal.Title>
            </ModalHeader>
            <ModalBody>
              {t(`installed_at`)}: {selectedInstallation.installed_at}, {t(`removed_at`)}:{" "}
              {selectedInstallation.removed_at}
              <SensorPlot
                sensor={selectedInstallation.sensor}
                plotType={plotType}
                setPlotType={setPlotType}
                installation={selectedInstallation}
              />
              {selectedInstallation.sensor.network_type == "NB" ? t(`imei`) : t(`dev_eui`)}:{" "}
              {selectedInstallation.sensor.network_type == "NB"
                ? selectedInstallation.sensor.imei
                : selectedInstallation.sensor.hardware_serial}
            </ModalBody>
          </Modal>
        )}
      </>
    </div>
  );
};

export default Tree;
