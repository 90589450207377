import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { MenuAlt1Icon, LineChartIcon } from "@components/icons";
// import "react-leaflet-fullscreen/dist/styles.css";
// import { getIrrigationArea } from "../../../../IrrigationArea/services/areaCrud";
// import PageNotFound from "@components/Error/PageNotFound";
import {
  FeatureGroup,
  MapContainer,
  Polygon,
  TileLayer,
  GeoJSON,
  Marker,
  ZoomControl,
  Popup,
  useMapEvents,
} from "react-leaflet";
import * as L from "leaflet";
// import { FullscreenControl } from "react-leaflet-fullscreen";
import { parentTreeIcon, childTreeIcon, noSensorTreeIcon } from "@helper/marker";
import { PopupContent } from "@modules/IrrigationArea/components/IrrigationAreaDetail";
import { handleTreeSelect } from "@modules/Projects/pages/ProjectDetail/ProjectDetailMapView/MapViewTreeList";
// import LeafletCanvasMarker from "./LeafletCanvasMarker";

function DisplayTrees({
  allTrees,
  tid,
  project,
  aId,
  setOpenTreePopup,
  selectedTree,
  setSelectedTree,
  setSelectedParentTree,
}) {
  if (selectedTree === undefined && tid !== undefined) {
    // NOTE: initial setup if tid is in url but tree not yet selected
    const tree = allTrees.filter((tree) => tree.id == tid)[0];
    setSelectedTree(tree);
    setOpenTreePopup(tree);
  }
  const [filteredTrees, setFilteredTrees] = useState(allTrees);
  const map = useMapEvents({
    zoomend: () => {
      setFilteredTrees(
        map ? allTrees.filter((tree) => map.getBounds().contains(tree.position)) : []
      );
      console.log("ZOOMEND", filteredTrees);
    },
    dragend: () => {
      setFilteredTrees(
        map ? allTrees.filter((tree) => map.getBounds().contains(tree.position)) : []
      );
      console.log("DRAGEND", filteredTrees);
    },
  });
  return filteredTrees?.map((tree, i) => {
    const health = tree.health_state;
    if (tree.position) {
      return (
        <div key={i}>
          {tree.position && (
            <LocationMarker
              key={tree.id}
              tree={tree}
              icon={tree.installations.length != 0 ? parentTreeIcon(health) : noSensorTreeIcon()}
              project={project}
              aId={aId}
              isActive={tree.id == tid ? true : false}
              setOpenTreePopup={setOpenTreePopup}
              selectedTree={selectedTree}
              setSelectedTree={setSelectedTree}
              setSelectedParentTree={setSelectedParentTree}
            />
          )}
          {tree?.represented_trees.map((childTree, i) => {
            const childHealth = childTree.health_state;
            return (
              <div key={i}>
                {childTree.position && (
                  <LocationMarker
                    key={childTree.id}
                    tree={childTree}
                    icon={childTreeIcon(childHealth)}
                    project={project}
                    aId={aId}
                    isActive={childTree.id == tid ? true : false}
                    setOpenTreePopup={setOpenTreePopup}
                    selectedTree={selectedTree}
                    setSelectedTree={setSelectedTree}
                    setSelectedParentTree={setSelectedParentTree}
                  />
                )}
              </div>
            );
          })}
        </div>
      );
    }
  });
}

function IrrigationAreaDetail({
  project,
  tid,
  lng,
  lat,
  selectedTree,
  mapCenter,
  setMap,
  aId,
  setOpenTreePopup,
  openTreePopup,
  openList,
  setSelectedTree,
  setSelectedParentTree,
}) {
  console.log("AREA", aId);
  console.log("SELECTED TREE", selectedTree);
  let allTrees = [];
  project.areas.map((area) => {
    allTrees.push(...area.tree_set);
  });
  // const [map, setMap] = useState(null);
  // useEffect(() => {
  //   allTrees = map ? allTrees.filter((tree) => map.getBounds().contains(tree.position)) : [];
  // }, [map ? map.getBounds() : []]);
  var lg = 10;
  if (openList) {
    lg = lg - 2;
  }
  if (openTreePopup) {
    lg = lg - 4;
  }

  return (
    <>
      <Col lg={`${lg}`}>
        {mapCenter && (
          <MapContainer
            center={mapCenter}
            zoom={16}
            style={{ height: "calc(100vh - 160px", borderRadius: "3px" }}
            // zoomControl={false}
            // zoomAnimaton={false}
            // animatingZoom={false}
            // animate={false}
            ref={setMap}
            maxZoom={22}
            // whenCreated={setMap}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              maxNativeZoom={19}
              maxZoom={22}
            />
            <ZoomControl position="bottomright" />
            {/* <FullscreenControl position="bottomright" /> */}
            <FeatureGroup>
              <div>
                <GeoJSON color="purple" data={[]} />
                <Polygon key={project.id} positions={[]} />
              </div>
            </FeatureGroup>
            <DisplayTrees
              allTrees={allTrees}
              tid={tid}
              project={project}
              aId={aId}
              setOpenTreePopup={setOpenTreePopup}
              selectedTree={selectedTree}
              setSelectedTree={setSelectedTree}
              setSelectedParentTree={setSelectedParentTree}
            />
            {/* <DisplayTrees allTrees={allTrees} tid={tid} project={project} aId={aId} /> */}
            {/* <LeafletCanvasMarker
            //   trees={allTrees}
            //   tid={tid}
            //   project={project}
            //   aId={aId}
            //   setOpenTreePopup={setOpenTreePopup}
            //   openTreePopup={openTreePopup}
            //   setSelectedTree={setSelectedTree}
            // />
        */}
            {lat && lng && selectedTree && (
              <MovementMarker tree={selectedTree} lat={lat} lng={lng} project={project} aId={aId} />
            )}
          </MapContainer>
        )}
      </Col>
    </>
  );
}

export default IrrigationAreaDetail;

const MovementMarker = ({ tree, lat, lng, project, aId }) => {
  const health = tree.health_state;
  const popupRef = useRef();

  useEffect(() => {
    popupRef.current.openPopup();
  }, [popupRef, tree]);

  return (
    <Marker
      position={[lat, lng]}
      icon={tree.installations.length != 0 ? parentTreeIcon(health) : noSensorTreeIcon()}
      ref={popupRef}
      // eventHandlers={{ click: setOpen}}
    >
      {/* <Popup minWidth={350} autoPan={false}>
        <div className="popup">
          <Row>
            <PopupContent tree={tree} project={project} aId={aId} />
          </Row>
        </div>
      </Popup> */}
    </Marker>
  );
};

// map marker position handle by external event
export function LocationMarker({
  isActive,
  tree,
  icon,
  project,
  aId,
  setOpenTreePopup,
  selectedTree,
  setSelectedTree,
  setSelectedParentTree,
}) {
  const map = useMapEvents({});
  map.invalidateSize();

  // const [refReady, setRefReady] = useState(false);
  // let popupRef = useRef();

  // useEffect(() => {
  //   if (refReady && isActive) {
  //     popupRef.openOn(map);
  //   }
  // }, [isActive, refReady, map]);

  // useEffect(() => {
  //   if (tree.position) map.flyTo(tree.position, map.getZoom("18"));
  // }, [tree.position, tree]);
  const navigate = useNavigate();
  const updateIconSize = (icon, newWidth, newHeight) => {
    // Extract the existing HTML from the icon
    const oldHtml = icon.options.html;
    // Use a regular expression to replace width and height in the SVG element
    let newHtml = oldHtml;
    let newAnchor = icon.options.iconAnchor;
    let newSize = icon.options.iconSize;
    if (oldHtml.includes('id="childTree"')) {
      // childree
      newHtml = oldHtml
        .replace(/width=\d+/, `width=${newWidth / 2}`)
        .replace(/height=\d+/, `height=${newHeight / 2}`)
        .replace(/rx=\d+/, `rx=${newWidth / 2}`)
        .replace(/ry=\d+/, `rx=${newHeight / 2}`)
        .replace(/transform="translate(8 8)"/, `transform="(${newHeight / 2} ${newWidth / 2})"`);
      newAnchor = [newWidth / 4, newHeight / 4];
      newSize = [newWidth / 2, newHeight / 2];
    } else if (oldHtml.includes("height")) {
      // parentTree
      newHtml = oldHtml
        .replace(/width=\d+/, `width=${newWidth}`)
        .replace(/height=\d+/, `height=${newHeight}`);
      newAnchor = [newWidth / 2, newHeight];
      newSize = [newWidth, newHeight];
    } else if (oldHtml.includes("cx")) {
      // Tree without Sensor
      newHtml = oldHtml
        .replace(/cx="\d"/, `cx="${newWidth / 8}"`)
        .replace(/cy="\d"/, `cy="${newHeight / 8}"`)
        .replace(/r="\d"/, `r="${newHeight / 8}"`);
      newAnchor = [newWidth / 8, newHeight / 8];
      newSize = [newWidth / 4, newHeight / 4];
    }

    // Create a new icon with the updated HTML
    return L.divIcon({
      ...icon.options,
      html: newHtml,
      iconSize: newSize,
      iconAnchor: newAnchor,
    });
  };
  function handleClick() {
    handleTreeSelect(
      tree,
      aId,
      project.id,
      navigate,
      selectedTree,
      setSelectedTree,
      setOpenTreePopup,
      setSelectedParentTree,
      map
    );
  }
  return (
    <Marker
      position={[tree?.position?.lat, tree?.position?.lng]}
      icon={tree === selectedTree ? updateIconSize(icon, 80, 80) : icon}
      eventHandlers={{
        click: handleClick,
      }}
    ></Marker>
  );
}

export const PopupContentProjectView = ({ tree, project, aId }) => {
  return (
    <div>
      {aId && (
        <Link
          to={`/project/${project.id}/list?selectedTree=${tree?.id}`}
          target="blank"
          style={{
            width: "35px",
            background: "#eee",
            display: "flex",
            justifyContent: "center",
            borderRadius: "3px",
            padding: "4px",
          }}
          title="View on map"
        >
          <MenuAlt1Icon width={"20px"} />
        </Link>
      )}
      {`This is tree ${tree.id}`}
    </div>
  );
};
export const PopupContent1 = ({ tree, project, aId }) => {
  const { t } = useTranslation();
  // const [phases] = useChoices("growth_phase");
  // const [locations] = useChoices("growth_phase");
  // const phase = phases && phases.filter((item) => item.key == tree?.phase);
  // const location = locations && locations.filter((item) => item.key == tree?.location);

  return (
    <>
      <Col lg={6} className="px-1">
        {aId && (
          <Link
            to={`/project/${project.id}/list?selectedTree=${tree?.id}`}
            target="blank"
            style={{
              width: "35px",
              background: "#eee",
              display: "flex",
              justifyContent: "center",
              borderRadius: "3px",
              padding: "4px",
            }}
            title="View on map"
          >
            <MenuAlt1Icon width={"20px"} />
          </Link>
        )}

        <ul className="list-group">
          <li className="list-group-item">
            <b>{t("name")}:</b> {tree.name}
          </li>
          <li className="list-group-item">
            {/*
						<b>{t("document irrigation")}: </b>
						<Button
							variant="outline-primary d-flex align-items-center"
							onClick={() => console.log("Button clicked")}
						>
							<>
								{t("Irrigation")} &nbsp;
								{<Spinner animation="border" size="sm" />}
							</>
						</Button>
          */}

            {/*   {location && location[0] ? location[0].value : ""} */}
            {/* </li> */}
            {/* <li className="list-group-item"> */}
            {/*   <b>{t("height")}: </b> {tree.height && tree.height + " m"} */}
          </li>
        </ul>
      </Col>
      <Col lg={6} className="px-1">
        {tree?.sensors?.length != 0 && (
          <Link
            to={`/project/${project.id}/datascience?areas=${aId}&trees=${tree?.id}`}
            target="blank"
            style={{
              width: "35px",
              background: "#eee	",
              display: "flex",
              justifyContent: "center",
              borderRadius: "3px",

              padding: "4px",
            }}
            title="View on chart"
          >
            <LineChartIcon width={"25px"} />
          </Link>
        )}

        {/* <ul className="list-group"> */}
        {/* <li className="list-group-item"> */}
        {/*   <b>{t("crown_diameter")}: </b> */}
        {/*   {tree.crown_diameter && tree.crown_diameter + " m"} */}
        {/* </li> */}
        {/* <li className="list-group-item"> */}
        {/*   <b>{t("trunk_circumference")}: </b> */}
        {/*   {tree.trunk_circumference && tree.trunk_circumference + " cm"} */}
        {/* </li> */}

        {/* <li className="list-group-item"> */}
        {/*   <b>{t("phase")}: </b> */}
        {/*   {phase && phase[0] ? phase[0].value : ""} */}
        {/* </li> */}
        {/*</ul>*/}
      </Col>
    </>
  );
};
